import React from 'react';

export const useScrollToTop = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    });
};

export const useScrollTo = (x, y) => {
    React.useEffect(() => {
        window.scrollTo(x, y);
    });
};
